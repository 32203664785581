<template>
  <section class="whatwedo" id="whatwedo">
    <h2 class="mb-5 text-brand-color">What We Do</h2>
    <div class="container">
      <div class="row justify-content-center mb-5">
        <div class="col-2 col-md-2 col-lg-1">
          <img class="what-step" src="../assets/img/whatStep1.svg" />
        </div>
        <div class="col-10 col-md-9 col-lg-7 mb-3">
          <div>
            <h4>Streamlined Algorithmic Process</h4>
            <br />
            <h6>
              We are empowering researchers to dedicate more time to scientific
              and creative thinking.
            </h6>
          </div>
        </div>
        <div class="col-7 col-md-5 col-lg-3">
          <img
            class="img-fluid"
            src="../assets/img/step1.svg"
            alt="STEP 1 IMAGE"
          />
        </div>
      </div>
      <div class="row justify-content-center mb-5">
        <div class="col-2 col-md-2 col-lg-1">
          <img class="what-step" src="../assets/img/whatStep2.svg" />
        </div>
        <div class="col-10 col-md-9 col-lg-7 mb-3">
          <div>
            <h4>Shortening the Path from Ideation to Product</h4>
            <br />
            <h6>
              Our proprietary platform offers a customizable high throughput
              "Design-Build-Test-Learn" workflow for biotech research.
            </h6>
          </div>
        </div>
        <div class="col-7 col-md-5 col-lg-3">
          <img
            class="img-fluid"
            src="../assets/img/step2.svg"
            alt="STEP 2 IMAGE"
          />
        </div>
      </div>
      <div class="row justify-content-center mb-5">
        <div class="col-2 col-md-2 col-lg-1">
          <img class="what-step" src="../assets/img/whatStep3.svg" />
        </div>
        <div class="col-10 col-md-9 col-lg-7 mb-3">
          <div>
            <h4>Breaking the Barriers of Biotechnology</h4>
            <br />
            <h6>
              Our user-centered design platform enables anyone to harness our
              software to bring their ideas to life.
            </h6>
          </div>
        </div>
        <div class="col-7 col-md-5 col-lg-3">
          <img
            class="img-fluid"
            src="../assets/img/step3.svg"
            alt="STEP 3 IMAGE"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.whatwedo {
  padding: 50px 0;
}
</style>
