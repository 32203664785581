<template>
  <div>
    <svg viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(70,50)">
        <g transform="scale(8)" fill="#cccccc">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.99991 7.5L7.48136 7.63757L6.2033 10.5021C6.09079 10.7543 6.20401 11.05 6.45619 11.1625C6.70837 11.275 7.00402 11.1618 7.11653 10.9096L8.45257 7.91506L10.4998 8.5V13.5C10.4998 14.0523 10.0521 14.5 9.49979 14.5H4.49979C3.94751 14.5 3.49979 14.0523 3.49979 13.5V7.5L3.5 6.5L6.99991 7.5ZM5 9.5C5.27614 9.5 5.5 9.27614 5.5 9C5.5 8.72386 5.27614 8.5 5 8.5C4.72386 8.5 4.5 8.72386 4.5 9C4.5 9.27614 4.72386 9.5 5 9.5ZM4.99979 12.5C4.99979 12.7761 4.77594 13 4.49979 13C4.22365 13 3.99979 12.7761 3.99979 12.5C3.99979 12.2239 4.22365 12 4.49979 12C4.77594 12 4.99979 12.2239 4.99979 12.5ZM7.49979 14C7.77594 14 7.99979 13.7761 7.99979 13.5C7.99979 13.2239 7.77594 13 7.49979 13C7.22365 13 6.99979 13.2239 6.99979 13.5C6.99979 13.7761 7.22365 14 7.49979 14ZM9.99979 12.5C9.99979 12.7761 9.77594 13 9.49979 13C9.22365 13 8.99979 12.7761 8.99979 12.5C8.99979 12.2239 9.22365 12 9.49979 12C9.77594 12 9.99979 12.2239 9.99979 12.5ZM8.49979 11C8.77594 11 8.99979 10.7761 8.99979 10.5C8.99979 10.2239 8.77594 10 8.49979 10C8.22365 10 7.99979 10.2239 7.99979 10.5C7.99979 10.7761 8.22365 11 8.49979 11Z"
            fill="pink"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.99979 16C2.44751 16 1.99979 15.5523 1.99979 15V5.13849L0.116699 2H1.99979H2.99979H9.99663L10.6852 0.456618C10.7977 0.204437 11.0934 0.0912138 11.3456 0.203727C11.5977 0.316241 11.711 0.611885 11.5985 0.864066L11.0916 2H11.9998V15C11.9998 15.5523 11.5521 16 10.9998 16H2.99979ZM10.9998 15H2.99979V4.84391L2.92854 4.74276L1.99979 3.19484L1.88289 3H1.99979H2.99979H9.55047L7.54274 7.5L7.48136 7.63757L8.45257 7.91506L8.63775 7.5L10.6455 3H10.9998V15Z"
            fill="#cccccc"
          />
        </g>
      </g>
      <text
        :x="textPosX"
        :y="textPosY"
        fill="#ff6f91"
        style="font-weight: 900; font-size: 2rem"
      >
        {{ name }}
      </text>
    </svg>
  </div>
</template>

<script>
export default {
  name: "OlabMediumSVGGen",
  props: ["name", "textPosX", "textPosY", "status"]
};
</script>
