class OlabRecipe {
  constructor(item) {
    if (item == null) {
      this.initRecipe();
    } else {
      let hp = Object.prototype.hasOwnProperty;
      // this.name = hp.call(item, "name") ? item.name : null;
      // this.olab_id = hp.call(item, "olab_id") ? item.olab_id : null;
      // this.olab_type = hp.call(item, "olab_type") ? item.olab_type : null;

      this.name = hp.call(item, "name") ? item.name : "";
      this.volume = hp.call(item, "volume") ? item.volume : 0;
      this.units = hp.call(item, "units") ? item.units : "L";
      this.desiredVolume = hp.call(item, "desiredVolume")
        ? item.desiredVolume
        : 0;
      this.ingredients = hp.call(item, "ingredients") ? item.ingredients : [];
      // this.created_by = hp.call(item, "created_by") ? item.created_by : null;
      // this.recipe_id = hp.call(item, "recipe_id") ? item.recipe_id : null;
      // this.purpose = hp.call(item, "purpose") ? item.purpose : null;
      // this.note = hp.call(item, "note") ? item.note : null;
    }
  }

  initRecipe() {
    console.log("OlabRecipe.initRecipe() ...");

    this.name = "";
    this.volume = 0;
    this.units = "L"; // Set default unit for recipes to liters
    this.desiredVolume = 0;
    this.ingredients = [
      {
        ingredientsValue: "",
        unit: "mL",
        substance: "",
        value: 0
      }
    ];
    // this.created_by = "";
    // this.recipe_id = "";
    // this.mfr_id = "";
    // this.lot_no = "";
    // this.cat_no = "";
    // this.expire_date = "";
    // this.purpose = "";
    // this.note = "";
  }

  set(item) {
    this.volume = item.volume;
    this.qty = item.qty;
    this.units = item.units;
    this.ingredients = item.ingredients;
  }
}

export { OlabRecipe };
