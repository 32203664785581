import { createApp } from "vue";
import App from "./App.vue";
import "bootstrap";
import router from "./router";

import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faLock,
  faLockOpen,
  faPlusSquare,
  faTrash,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";

library.add(faLock, faLockOpen, faPlusSquare, faTrash, faTrashAlt);

createApp(App).use(router).mount("#app");
