<template>
  <div class="calulatorface">
    <div class="container text-left mt-3">
      <h1>Medium / Buffer Calculator</h1>
      <p>
        This bidirectional calculator provides an easy-to-use approach to
        calculate buffer solution.
      </p>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <div class="input-group my-3">
            <div class="input-group-prepend">
              <label class="input-group-text lead" for="recipeSelection"
                >Recipes</label
              >
            </div>
            <select
              class="custom-select"
              v-model="selectedRecipe"
              id="recipeSelection"
            >
              <option disabled value="">Please select one</option>
              <option v-for="recipe in recipes" v-bind:key="recipe.name">
                {{ recipe.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="container text-left" v-if="selectedRecipe">
      <p></p>
      <h3>{{ recipes[recipeIndex].name }}</h3>
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-12">
          <div class="card">
            <div class="card-header">
              <form class="form-inline" v-on:submit.prevent="onSubmit">
                <span class="h4">Recipe</span>
                <span class="mx-2">
                  <button v-if="watchField === 'recipeFormula'" class="btn">
                    <font-awesome-icon
                      class="mr-1 text-warning"
                      icon="lock-open"
                      @click="watchField = 'volume'"
                    >
                      &nbsp;</font-awesome-icon
                    >
                  </button>
                  <button v-else class="btn">
                    <font-awesome-icon
                      class="mr-1 text-warning"
                      icon="lock"
                      @click="watchField = 'recipeFormula'"
                    >
                    </font-awesome-icon>
                  </button>
                  <input
                    name="Recipe volume"
                    type="number"
                    class="mx-2 form-control text-primary"
                    style="width: 120px; text-align: center"
                    v-model="recipes[recipeIndex].volume"
                    v-bind:disabled="watchField != 'recipeFormula'"
                  />
                  <span class="mx-2 text-primary">
                    {{ recipes[recipeIndex].units }}
                  </span>
                  <span>Solution</span>
                </span>
              </form>
            </div>
            <div class="card-body">
              <div class="form-group">
                <form
                  class="form-inline"
                  v-on:submit.prevent="onSubmit"
                  v-for="ingredient in recipes[recipeIndex].ingredients"
                  v-bind:key="ingredient.substance"
                >
                  <p>
                    <input
                      name="ingredient.substance"
                      type="number"
                      min="0"
                      class="mx-2 form-control"
                      style="width: 120px; text-align: center"
                      v-model="ingredient.ingredientsValue"
                      v-bind:disabled="watchField != 'recipeFormula'"
                      @keyup="clampToNonNegative(ingredient)"
                    />{{ ingredient.unit }} of
                    {{ ingredient.substance }}
                  </p>
                </form>
              </div>

              <div class="pb-3">
                <span>Adjust final volume to</span>
                <span class="h5 text-primary bg-light mx-1"
                  >{{ recipes[recipeIndex].volume }}
                  {{ recipes[recipeIndex].units }}</span
                >
                <span>with Milli-Q Water</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="card">
            <div class="card-header">
              <form class="form-inline" v-on:submit.prevent="onSubmit">
                <span class="h4">Desired</span>
                <span class="mx-2">
                  <button
                    v-if="watchField === 'volume'"
                    class="btn"
                    v-bind:disabled="watchField === 'volume'"
                  >
                    <font-awesome-icon
                      class="mr-1"
                      v-bind:class="
                        watchField === 'volume'
                          ? 'text-secondary'
                          : 'text-warning'
                      "
                      icon="lock-open"
                    >
                      &nbsp;</font-awesome-icon
                    >
                  </button>
                  <button v-else class="btn">
                    <font-awesome-icon
                      class="mr-1 text-warning"
                      icon="lock"
                      @click="watchField = 'volume'"
                    >
                    </font-awesome-icon>
                  </button>
                  <input
                    name="Desired volume"
                    type="number"
                    class="mx-2 form-control text-primary"
                    style="width: 120px; text-align: center"
                    v-model="desiredVolume"
                    @click="watchField = 'volume'"
                  />
                  {{ calculateVolume(recipeIndex) }}
                  <span class="mx-2 text-primary">{{
                    recipes[recipeIndex].units
                  }}</span>
                  <span>Solution</span>
                </span>
              </form>
            </div>
            <div class="card-body">
              <div class="form-group">
                <form
                  class="form-inline"
                  v-on:submit.prevent="onSubmit"
                  v-for="(ingredient, k) in recipes[recipeIndex].ingredients"
                  v-bind:key="ingredient.substance"
                >
                  <p>
                    <button
                      v-if="watchField == k"
                      class="mr-1 btn"
                      @click="watchField = 'volume'"
                    >
                      <font-awesome-icon
                        class="text-warning"
                        icon="lock-open"
                      ></font-awesome-icon>
                    </button>
                    <button
                      v-else
                      class="mr-1 btn"
                      v-bind:disabled="ingredient.ingredientsValue <= 0"
                      @click="changeWatchField(k)"
                    >
                      <font-awesome-icon
                        v-bind:class="
                          ingredient.ingredientsValue <= 0
                            ? 'text-secondary'
                            : 'text-warning'
                        "
                        icon="lock"
                      ></font-awesome-icon>
                    </button>
                    <input
                      name="ingredient.substance"
                      type="number"
                      class="mx-2 form-control"
                      v-bind:disabled="ingredient.ingredientsValue <= 0"
                      style="width: 120px; text-align: center"
                      v-model="ingredient.value"
                      @click="changeWatchField(k)"
                    />
                    <span v-if="watchField != k">
                      {{ calculateSubstanceValue(recipeIndex, ingredient) }}
                    </span>
                    {{ ingredient.unit }} of {{ ingredient.substance }}
                  </p>
                </form>
                <div>
                  <span>Adjust final volume to</span>
                  <span class="h5 text-primary bg-light mx-1"
                    >{{ desiredVolume }} {{ recipes[recipeIndex].units }}</span
                  >
                  <span>with Milli-Q Water</span>
                </div>
              </div>
              <div class="col-md-12 text-center my-4">
                <!--                 <button
                  class="btn btn-brand mr-2"
                  v-on:click="unlock"
                  v-bind:disabled="noWatchOther === false"
                >
                  Unlock
                </button> -->
                <button class="btn btn-brand mr-2" v-on:click="reset">
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- <p class="my-3">
          <span class="font-weight-bold">Note: </span>Should the solution appear
          orang/yellow, it may be necessary to raise the pH by adding a suitable
          base, such as 5&nbsp;M&nbsp;NaOH, dropwise
        </p> -->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { OlabUtils } from "@/olab/olabutils.js";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { recipes } from "@/assets/data/medium_buffers.js";

export default {
  name: "MediumBuffer",
  components: {
    FontAwesomeIcon
  },
  data() {
    return {
      selectedRecipe: "",
      recipeIndex: 0,
      watchField: "volume",
      noWatchOther: true, //temp set to true normal is false
      units: "mL",
      desiredVolume: OlabUtils.fixFloatTo(0, 4),
      recipes
    };
  },
  props: ["errorObj"],
  emits: ["setErrorMessage"],
  methods: {
    onSubmit() {
      // This is a NOP to prevent page reload on return key
      // console.log("AFTER SUBMIT");
    },
    reset() {
      this.desiredVolume = 0;
      this.watchField = "volume";
      // console.log("reset");
    },
    unlock() {
      this.noWatchOther = true; //temp set to true
      // console.log("unlock");
    },
    clampToNonNegative(ingredient) {
      if (!isNaN(ingredient.ingredientsValue)) {
        ingredient.ingredientsValue =
          ingredient.ingredientsValue < 0 ? 0 : ingredient.ingredientsValue;
      }
    },
    calculateSubstanceValue(recipeIndex, ingredient) {
      if (this.desiredVolume < 0) {
        this.desiredVolume = OlabUtils.fixFloatTo(0, 4);
      }
      const value =
        (parseFloat(ingredient.ingredientsValue) *
          parseFloat(this.desiredVolume)) /
        parseFloat(this.recipes[recipeIndex].volume);
      if (!isNaN(value)) {
        ingredient.value = OlabUtils.fixFloatTo(value, 4);
      }
    },
    calculateVolume(k) {
      if (this.watchField === "volume" || this.watchField === "recipeFormula") {
        return;
      } else {
        if (this.recipes[k].ingredients[this.watchField].value < 0) {
          this.recipes[k].ingredients[this.watchField].value =
            OlabUtils.fixFloatTo(0, 4);
        }
        const value =
          (this.recipes[k].volume *
            this.recipes[k].ingredients[this.watchField].value) /
          this.recipes[k].ingredients[this.watchField].ingredientsValue;
        if (!isNaN(value)) {
          this.desiredVolume = OlabUtils.fixFloatTo(value, 2);
        }
      }
    },
    changeWatchField(k) {
      if (this.recipes[this.recipeIndex].ingredients[k].ingredientsValue > 0) {
        this.watchField = k;
      }
    },
    computeRecipeIndex(recipeName) {
      // console.log("recipe name = ", recipeName);
      for (let i = 0; i < this.recipes.length; i++) {
        // console.log("this.recipes[", i, "].name = ", this.recipes[i].name);
        if (this.recipes[i].name == recipeName) {
          // console.log("Recipe index = ", this.recipeIndex);
          // console.log("Recipe i = ", i);
          if (this.recipeIndex >= 0 && this.recipeIndex != i) {
            // console.log(`this.recipeIndex = ${this.recipeIndex}, i = ${i}`);
            const rIndex = this.recipeIndex;
            const ingrArr = this.recipes[rIndex].ingredients;
            // Reset old ingredients' value to 0
            for (let j = 0; j < ingrArr.length; j++) {
              ingrArr[j].value = OlabUtils.fixFloatTo(0, 4);
            }
          }

          this.recipeIndex = i;
        }
      }
    }
  },
  watch: {
    selectedRecipe() {
      this.computeRecipeIndex(this.selectedRecipe);
      this.reset();
      // console.log("selected Recipe changes and desiredVolume is set to 0");
    }
  }
};
</script>

<style scoped>
.calulatorface {
  padding: 50px 0;
  background-color: #fdf2e9;
}
</style>
