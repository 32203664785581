import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  // Insert Firebase configuration here.
  apiKey: "AIzaSyAPbGs5umtqKGtvZZFgIsapliy2m-uKwrM",
  authDomain: "olab-landingpage.firebaseapp.com",
  projectId: "olab-landingpage",
  storageBucket: "olab-landingpage.appspot.com",
  messagingSenderId: "76873485973",
  appId: "1:76873485973:web:ea4e761ec6484d3ee6d59c",
  measurementId: "G-8Q0VVVJJM1"
};

// init firebase
firebase.initializeApp(firebaseConfig);

// init firestore service
const dbFS = firebase.firestore();
const timestampFS = firebase.firestore.FieldValue.serverTimestamp;

export { dbFS, timestampFS };
