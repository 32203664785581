<template>
  <section class="mission" id="mission">
    <div class="container">
      <div class="row justify-content-center">
        <!-- <div class="col-10 col-md-8 col-lg-6">
          <img
            class="img-fluid"
            src="../assets/img/heroimg2.svg"
            alt="Hero Image"
          />
        </div> -->
        <div class="col-12 col-md-12 col-lg-12">
          <br />
          <p class="h2 text-center title">
            Empowering people to do biology, easier than ever!
          </p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-11 mx-auto lead content">
          <div class="text">
            <p class="text-left">
              In the fast-paced world of synthetic biology, OLAB is committed to
              reshaping the research landscape with our innovative approach
              centered around the Ideation-Design-Build-Test-Learn (IDBTL)
              cycle. We acknowledge the intricate challenges inherent in each
              stage of this cycle and recognize the daunting task it presents to
              researchers, operators, and managers alike.
            </p>
            <p class="text-left">
              At OLAB, our mission is clear: to streamline and optimize the
              IDBTL process. We firmly believe in harnessing the power of
              algorithmic processes and artificial intelligence to enhance
              efficiency and effectiveness. By integrating cutting-edge
              technology with our deep understanding of synthetic biology, we
              strive to empower researchers and eliminate the barriers hindering
              progress in this field.
            </p>
            <p class="text-left">
              Driven by our passion for leveraging synthetic biology to improve
              the world around us, we are dedicated to breaking down the
              obstacles faced by scientific researchers. Join us on our journey
              to unlock the full potential of synthetic biology and pave the way
              for a brighter future.
            </p>
          </div>

          <p class="text">
            <span>
              <!-- We believe biotechnology can help make our world better. We work
              to lower and eliminate the barriers in biotechnology for people.
              If you want to join us in pursuing this goal, -->
              <!-- <router-link
                class="btn btn-brand"
                :to="{
                  name: 'Contactus',
                  params: { sectionName: 'contactus' }
                }"
                >contact us</router-link
              > -->
            </span>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.mission {
  padding: 50px 0;
  // background-color: #fdf2e9;
  background-image: linear-gradient(
      45deg,
      #1d2481,
      #306d66,
      #7d7d54,
      #816657,
      #725072,
      #c2bd97
    ),
    url("../assets/img/firstSlide.svg");
  background-repeat: no-repeat, no-repeat;
  background-blend-mode: multiply, multiply;
  background-size: 500%, cover;
  animation: bg-animation 30s infinite alternate,
    bg-animation 100s infinite alternate;
}

@keyframes bg-animation {
  0% {
    background-position: top left;
  }
  100% {
    background-position: bottom right;
  }
}

.title {
  margin: 0, 0, 0.5em;
  // text-transform: uppercase;
  font-weight: 900;
  font-size: 2.5rem;
  background-image: linear-gradient(45deg, #ff6f91, #ff9671, #ffc75f, #f9f871);
  background-clip: text;
  color: transparent;
  background-size: 300%;
  animation: bg-animation 20s infinite alternate;
}

.text {
  padding-top: 2em;
  padding-left: 2em;
  padding-right: 2em;
  margin: 0, 0, 0.5em;
  font-weight: 500;
  font-size: 1.5rem;
  background-image: linear-gradient(
    45deg,
    #ff6f91,
    #ff9671,
    #ffc75f,
    #f9f871,
    #c9c8c1
  );
  background-clip: text;
  color: transparent;
  background-size: 500%;
  animation: bg-animation 60s infinite alternate;
}
</style>
