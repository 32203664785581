<template>
  <div v-if="calc">
    <div class="calculatorface">
      <!-- TODO: rm bootstrap -->
      <div class="container text-left mt-3">
        <h1>Microbial Growth Calculator</h1>
        <p>
          This bidirectional microbial growth calculator provides an easy-to-use
          approach to calculate microbial growth.
        </p>
        <p>
          You fill in any 5 of the 6 components and we will compute the
          remaining component
        </p>
      </div>
      <div
        style="
          display: grid;
          grid-template-columns: 2fr 2fr 2fr;
          row-gap: 0.25rem;
          column-gap: 0.25rem;
          align-items: center;
        "
      >
        <label class="calcLabel" for="Stock_OD" style="order: 1"
          >Stock&nbsp;OD</label
        >
        <!-- :class adds invalid styling -->
        <!-- TODO: user menu for custom settings saved as a cookie.
          Ideas:
          - custom limits
          - copy paste values somehow(?)
          - reorder items
        -->
        <input
          name="Stock_OD"
          type="number"
          class="calc-input-field"
          :class="sodValidState"
          placeholder="Stock Density"
          :value="calc.stockOD"
          @input="updateStockOD"
          min="0"
          style="order: 2"
          :disabled="
            inValidMsg !== null &&
            !invalidSOD &&
            !(outputField === 'calculateStockOD')
          "
          :readonly="outputField === 'calculateStockOD'"
        />
        <!-- TODO: example logic check using getter function in calculator class-->
        <!-- TODO: reorder elements (html) so that inputs are inline, but unit select is still tied to data properly and in correct grid position -->
        <div class="select-wrapper" style="order: 3">
          <div class="calc-unit-label">OD600</div>
        </div>
        <label class="calcLabel" for="Culture_OD" style="order: 4"
          >Culture&nbsp;OD</label
        >
        <input
          name="Culture_OD"
          type="number"
          class="calc-input-field"
          :class="codValidState"
          placeholder="Culture Density"
          :value="calc.cultureOD"
          @input="updateCultureOD"
          min="0"
          style="order: 5"
          :disabled="
            inValidMsg !== null &&
            !invalidCOD &&
            !(outputField === 'calculateCultureOD')
          "
          :readonly="outputField === 'calculateCultureOD'"
        />
        <div class="select-wrapper" style="order: 6">
          <div class="calc-unit-label">OD600</div>
        </div>
        <label class="calcLabel" for="Generation_Time" style="order: 7"
          >Generation&nbsp;Time</label
        >
        <input
          name="Generation_Time"
          type="number"
          class="calc-input-field"
          :class="gtValidState"
          placeholder="Generation Time"
          :value="userGenerationTime"
          @input="updateGenerationTime"
          min="0"
          style="order: 8"
          :disabled="
            inValidMsg !== null &&
            !invalidGT &&
            !(outputField === 'calculateGenerationTime')
          "
          :readonly="outputField === 'calculateGenerationTime'"
        />
        <div class="select-wrapper" style="order: 9">
          <select class="calc-unit-selection" v-model="userGenerationTimeUnit">
            <option value="hr">hr</option>
            <option value="min">min</option>
          </select>
        </div>
        <label class="calcLabel" for="Culturing_Time" style="order: 10"
          >Culturing&nbsp;Time</label
        >
        <input
          name="Culturing_Time"
          type="number"
          class="calc-input-field"
          :class="ctValidState"
          placeholder="Culturing Time"
          :value="userCultureTime"
          @input="updateCultureTime"
          min="0"
          style="order: 11"
          :disabled="
            inValidMsg !== null &&
            !invalidCT &&
            !(outputField === 'calculateCultureTime')
          "
          :readonly="outputField === 'calculateCultureTime'"
        />
        <div class="select-wrapper" style="order: 12">
          <select class="calc-unit-selection" v-model="userCultureTimeUnit">
            <option value="hr">hr</option>
            <option value="min">min</option>
          </select>
        </div>
        <label class="calcLabel" for="Culture_Volume" style="order: 13"
          >Culture&nbsp;Volume</label
        >
        <input
          name="Culture_Volume"
          type="number"
          class="calc-input-field"
          :class="cvValidState"
          placeholder="Culture Volume"
          :value="userCultureVolume"
          @input="updateCultureVolume"
          min="0"
          style="order: 14"
          :disabled="
            inValidMsg !== null &&
            !invalidCV &&
            !(outputField === 'calculateCultureVolume')
          "
          :readonly="outputField === 'calculateCultureVolume'"
        />
        <div class="select-wrapper" style="order: 15">
          <select class="calc-unit-selection" v-model="userCultureVolumeUnit">
            <option value="mL">mL</option>
            <option value="L">L</option>
          </select>
        </div>
        <label class="calcLabel" for="Stock_Volume" style="order: 16"
          >Stock&nbsp;Volume</label
        >
        <input
          name="Stock_Volume"
          type="number"
          class="calc-input-field"
          :class="svValidState"
          placeholder="Stock Volume"
          :value="userStockVolume"
          @input="updateStockVolume"
          min="0"
          style="order: 17"
          :disabled="
            inValidMsg !== null &&
            !invalidSV &&
            !(outputField === 'calculateStockVolume')
          "
          :readonly="outputField === 'calculateStockVolume'"
        />
        <div class="select-wrapper" style="order: 18">
          <select class="calc-unit-selection" v-model="userStockVolumeUnit">
            <option value="mL">mL</option>
            <option value="L">L</option>
          </select>
        </div>
      </div>
      <!-- TODO:rm bootstrap -->
      <div class="col-md-12 text-center my-4">
        <button class="btn btn-brand mr-2" v-on:click="reset">Reset</button>
      </div>
      <!-- <div class="calc-invalid-msg" v-if="invalidInputFlag"> -->
      <div>
        <div class="calc-invalid-msg">
          {{ inValidMsg }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { OlabCalcMicrobial } from "@/olab/olabcalcmicrobial";
export default {
  name: "MicrobialGrowth",
  data() {
    return {
      roundPrecisionDigit: 4,
      unitRatio: {
        hr: 1,
        min: 1 / 60
      },
      userGenerationTime: "",
      userCultureTime: "",
      userCultureVolume: "",
      userStockVolume: "",
      userGenerationTimeUnit: "hr",
      userCultureTimeUnit: "hr",
      userCultureVolumeUnit: "mL",
      userStockVolumeUnit: "mL",
      //
      calc: new OlabCalcMicrobial(),
      invalidInputFlag: false,
      invalidSOD: false, // flags needed for input field highlight on invalid input
      invalidCOD: false,
      invalidGT: false,
      invalidCT: false,
      invalidCV: false,
      invalidSV: false,
      inValidMsg: null,
      outputField: null,
      statusObj: {
        message: "",
        error: ""
      },
      nonNegativeMsg: "Non negative value only",
      exceedMaxMsg: "Can't exceed 100"
    };
  },
  methods: {
    updateUserGenerationTime() {
      if (this.userGenerationTime != "") {
        const multiplier =
          this.userGenerationTimeUnit == "hr" ? 1 : this.unitRatio.min;
        this.userGenerationTime = this.calc.generationTime / multiplier;
        console.log("updateUserGenerationTime: ", this.userGenerationTime);
      }
    },
    updateUserCultureTime() {
      if (this.userCultureTime != "") {
        const multiplier =
          this.userCultureTimeUnit == "hr" ? 1 : this.unitRatio.min;
        this.userCultureTime = this.calc.cultureTime / multiplier;
        console.log("updateUserCultureTime: ", this.userCultureTime);
      }
    },
    updateUserCultureVolume() {
      if (this.userCultureVolume != "") {
        const multiplier = this.userCultureVolumeUnit == "mL" ? 1 : 1000;
        this.userCultureVolume = this.calc.cultureVolume / multiplier;
        console.log("updateUserCultureVolume: ", this.userCultureVolume);
      }
    },
    updateUserStockVolume() {
      if (this.userStockVolume != "") {
        const multiplier = this.userStockVolumeUnit == "mL" ? 1 : 1000;
        this.userStockVolume = this.calc.stockVolume / multiplier;
        console.log("updateUserStockVolume: ", this.userStockVolume);
      }
    },
    // TODO: rename
    calcGenerationTime() {
      if (this.userGenerationTime != "") {
        const multiplier =
          this.userGenerationTimeUnit == "hr" ? 1 : this.unitRatio.min;
        this.calc.generationTime = this.userGenerationTime * multiplier;
        if (this.outputField != null) {
          this.calc.recalculateField(this.outputField);
        }
      }
    },
    calcCultureTime() {
      if (this.userCultureTime != "") {
        const multiplier =
          this.userCultureTimeUnit == "hr" ? 1 : this.unitRatio.min;
        this.calc.cultureTime = this.userCultureTime * multiplier;
        if (this.outputField != null) {
          this.calc.recalculateField(this.outputField);
        }
      }
    },
    calcCultureVolume() {
      //layer to abstract CV value to a combination of user text input and user selection input
      if (this.userCultureVolume != "") {
        const multiplier = this.userCultureVolumeUnit == "mL" ? 1 : 1000;
        this.calc.cultureVolume = this.userCultureVolume * multiplier;
        if (this.outputField != null) {
          // TODO: determine best way to call a recalculate on input field or unit selection update
          this.calc.recalculateField(this.outputField);
        }
      }
    },
    calcStockVolume() {
      if (this.userStockVolume != "") {
        const multiplier = this.userStockVolumeUnit == "mL" ? 1 : 1000;
        this.calc.stockVolume = this.userStockVolume * multiplier;
        if (this.outputField != null) {
          this.calc.recalculateField(this.outputField);
        }
      }
    },
    setOutputField() {
      // Previous logic accounting for case where input field is 0 (vs "")
      // Should be fine for 0 to block, better to explicitly have "" mark the calculateField
      if (this.calc.stockOD == "") {
        this.outputField = "calculateStockOD";
      } else if (this.calc.cultureOD == "") {
        this.outputField = "calculateCultureOD";
      } else if (this.calc.generationTime == "") {
        this.outputField = "calculateGenerationTime";
      } else if (this.calc.cultureTime == "") {
        this.outputField = "calculateCultureTime";
      } else if (this.calc.cultureVolume == "") {
        this.outputField = "calculateCultureVolume";
      } else if (this.calc.stockVolume == "") {
        this.outputField = "calculateStockVolume";
      }
      console.log("setOutputField: ", this.outputField);
    },
    // firstSetterCalculate to trigger the one-time setting of outputfield should only happen once before resetting
    // automatically detect when 5 fields are entered, select empty field as output, calculate result
    firstSetterCalculate() {
      let count =
        (this.calc.stockOD === "" ? 0 : 1) +
        (this.calc.cultureOD === "" ? 0 : 1) +
        (this.calc.generationTime === "" ? 0 : 1) +
        (this.calc.cultureTime === "" ? 0 : 1) +
        (this.calc.cultureVolume === "" ? 0 : 1) +
        (this.calc.stockVolume === "" ? 0 : 1);
      console.log("***count: ", count);
      if (count == 5 && this.outputField == null) {
        this.setOutputField();
      }
      if (this.outputField != null) {
        this.calc.recalculateField(this.outputField);
      }
    },
    //TODO: set default values here
    reset() {
      this.calc.reset();
      this.userGenerationTime = "";
      this.userCultureTime = "";
      this.userCultureVolume = "";
      this.userStockVolume = "";
      this.userGenerationTimeUnit = "hr";
      this.userCultureTimeUnit = "hr";
      this.userCultureVolumeUnit = "mL";
      this.userStockVolumeUnit = "mL";
      this.outputField = null;
      this.statusObj = {
        message: "",
        error: ""
      };
      this.invalidSOD = false;
      this.invalidCOD = false;
      this.invalidGT = false;
      this.invalidCT = false;
      this.invalidCV = false;
      this.invalidSV = false;
      this.inValidMsg = null;
      console.log("reset invalidSOD: ", this.invalidSOD);
      console.log("reset invalidMsg: ", this.invalidMsg);
    },
    updateStockOD(event) {
      console.log("updateStockOD =", event.target.value);
      this.calc.stockOD = event.target.value;
      // Template for invalid message
      if (this.calc.stockOD < 0) {
        this.invalidSOD = true;
        this.inValidMsg = "Non negative value only";
      } else {
        this.invalidSOD = false;
        this.inValidMsg = null;
        this.firstSetterCalculate();
      }
    },
    updateCultureOD(event) {
      console.log("updateCultureOD =", event.target.value);
      this.calc.cultureOD = event.target.value;
      // Template for invalid message
      if (this.calc.cultureOD < 0) {
        this.invalidCOD = true;
        this.inValidMsg = "Non negative value only";
      } else {
        this.invalidCOD = false;
        this.inValidMsg = null;
        this.firstSetterCalculate();
      }
    },
    updateGenerationTime(event) {
      console.log("updateGenerationTime =", event.target.value);
      this.userGenerationTime = event.target.value;
      // Template for invalid message
      if (this.userGenerationTime < 0) {
        this.invalidGT = true;
        this.inValidMsg = "Non negative value only";
      } else if (this.userGenerationTime === "") {
        this.invalidGT = false;
        this.inValidMsg = null;
        this.calc.generationTime = "";
      } else {
        this.invalidGT = false;
        this.inValidMsg = null;
        this.calcGenerationTime();
        this.firstSetterCalculate();
      }
    },
    updateCultureTime(event) {
      console.log("updateCultureTime =", event.target.value);
      this.userCultureTime = event.target.value;
      // Template for invalid message
      if (this.userCultureTime < 0) {
        this.invalidCT = true;
        this.inValidMsg = "Non negative value only";
        // logic should include unit
        // } else if (this.userCultureTime > 100) {
        //   this.invalidCT = true;
        //   this.inValidMsg = "Can't exceed 100";
      } else if (this.userCultureTime === "") {
        this.invalidCT = false;
        this.inValidMsg = null;
        this.calc.cultureTime = "";
      } else {
        this.invalidCT = false;
        this.inValidMsg = null;
        this.calcCultureTime();
        this.firstSetterCalculate();
      }
    },
    updateCultureVolume(event) {
      console.log("updateCultureVolume =", event.target.value);
      this.userCultureVolume = event.target.value;
      // Template for invalid message
      if (this.userCultureVolume < 0) {
        this.invalidCV = true;
        this.inValidMsg = "Non negative value only";
      } else if (this.userCultureVolume === "") {
        this.invalidCV = false;
        this.inValidMsg = null;
        this.calc.cultureVolume = "";
      } else {
        this.invalidCV = false;
        this.inValidMsg = null;
        this.calcCultureVolume();
        this.firstSetterCalculate();
      }
    },
    updateStockVolume(event) {
      console.log("updateStockVolume =", event.target.value);
      this.userStockVolume = event.target.value;
      // Template for invalid message
      if (this.userStockVolume < 0) {
        this.invalidSV = true;
        this.inValidMsg = "Non negative value only";
      } else if (this.userStockVolume === "") {
        this.invalidSV = false;
        this.inValidMsg = null;
        this.calc.stockVolume = "";
      } else {
        this.invalidSV = false;
        this.inValidMsg = null;
        this.calcStockVolume();
        this.firstSetterCalculate();
      }
    }
  },
  computed: {
    // validation styling for each field
    sodValidState() {
      if (this.invalidSOD) {
        return "calc-invalid-input-field";
      }
      return "";
    },
    codValidState() {
      if (this.invalidCOD) {
        return "calc-invalid-input-field";
      }
      return "";
    },
    gtValidState() {
      if (this.invalidGT) {
        return "calc-invalid-input-field";
      }
      return "";
    },
    ctValidState() {
      if (this.invalidCT) {
        return "calc-invalid-input-field";
      }
      return "";
    },
    cvValidState() {
      if (this.invalidCV) {
        return "calc-invalid-input-field";
      }
      return "";
    },
    svValidState() {
      if (this.invalidSV) {
        return "calc-invalid-input-field";
      }
      return "";
    }
  },
  watch: {
    userGenerationTimeUnit() {
      console.log(
        "watch: userGenerationTimeUnit = ",
        this.userGenerationTimeUnit
      );
      this.updateUserGenerationTime();
    },
    "calc.generationTime"() {
      if (this.outputField == "calculateGenerationTime") {
        const multiplier =
          this.userGenerationTimeUnit == "hr" ? 1 : this.unitRatio.min;
        this.userGenerationTime = this.calc.generationTime / multiplier;
        console.log("watch: calc.generationTime");
      }
    },
    userCultureTimeUnit() {
      console.log("watch: userCultureTimeUnit = ", this.userCultureTimeUnit);
      this.updateUserCultureTime();
    },
    "calc.cultureTime"() {
      if (this.outputField == "calculateCultureTime") {
        const multiplier =
          this.userCultureTimeUnit == "hr" ? 1 : this.unitRatio.min;
        this.userCultureTime = this.calc.cultureTime / multiplier;
        console.log("watch: calc.cultureTime");
      }
    },
    userCultureVolumeUnit() {
      console.log(
        "watch: userCultureVolumeUnit = ",
        this.userCultureVolumeUnit
      );
      this.updateUserCultureVolume();
    },
    "calc.cultureVolume"() {
      if (this.outputField == "calculateCultureVolume") {
        const multiplier = this.userCultureVolumeUnit == "mL" ? 1 : 1000;
        this.userCultureVolume = this.calc.cultureVolume / multiplier;
        console.log("watch: calc.cultureVolume");
      }
    },
    userStockVolumeUnit() {
      console.log("watch: userStockVolumeUnit = ", this.userStockVolumeUnit);
      this.updateUserStockVolume();
    },
    "calc.stockVolume"() {
      if (this.outputField == "calculateStockVolume") {
        const multiplier = this.userStockVolumeUnit == "mL" ? 1 : 1000;
        this.userStockVolume = this.calc.stockVolume / multiplier;
        console.log("watch: calc.stockVolume");
      }
    }
  }
};
</script>

<style scoped>
.calc-invalid-msg {
  /* display: block; */
  color: red;
}
.calc-invalid-msg:disabled {
  display: none;
}
.select-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100px, 100%), 0.125fr));
  height: 100%;
  align-items: center;
}

.calc-input-field {
  display: block;
  width: 100%;
  /* max-width: 200px; */
  /* height: calc(1.5em + 0.75rem + 2px); */
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  /* transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; */
  /* transition: border-color 0.15s ease-in-out; */
}
.calc-input-field:focus {
  /* TODO: fix focus highlight for input field */
  /* border: 1px solid #add8e6; */
  box-shadow: #aad8e6 0.15s ease-in-out;
  /* TODO: select blue color */
}
.calc-input-field:read-only {
  background-color: lightblue;
  box-shadow: inset 0 0 4px 2px rgb(91, 129, 255);
}
.calc-input-field:disabled {
  box-shadow: none;
  background: lightgray;
}
.calcLabel {
  display: inline-block;
  align-items: middle;
  /* vertical-align: middle; */
  /* max-width: 100%; */
  /* padding: 0rem 1rem; */
  margin-bottom: 0.5rem;
  font-size: 1rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
  /* justify-content: flex-end; */
  text-align: right;
}
.calc-unit-selection {
  /* display: block; */
  /* padding: 0.375rem 0.75rem; */
  border: 1px solid #ced4da;
  background-color: #e9ecef;
  background-clip: padding-box;
  border-radius: 0.25rem;
  /* width: 50rem; */
  /* height: calc(1.5em + 0.75rem + 2px); */
  height: 100%;
  vertical-align: middle;
  font-size: 1rem;
}
.calc-unit-label {
  /* display: block; */
  /* padding: 0.375rem 0.75rem; */
  padding-top: 0.5em;
  padding-left: 0;
  margin-left: 0;
  border: 1px solid #ced4da;
  background-color: #e9ecef;
  background-clip: padding-box;
  border-radius: 0.25rem;
  /* width: 50rem; */
  /* height: calc(1.5em + 0.75rem + 2px); */
  height: 100%;
  /* vertical-align: middle; */
  font-size: 1rem;
}
.calculatorface {
  padding: 50px 0;
  background-color: #fdf2e9;
}
.flex-container {
  display: flex;
  flex-grow: 0; /* even spacing*/
  flex-shrink: 0;
  flex-basis: 100%;
  flex-direction: row;
  /* justify-content: flex-start; */
  /* align-items: flex-start; */
  /* flex-wrap: nowrap; */
  /* align-content: flex-start; */
  gap: 0.1em;
  min-width: 200px;
  width: 100%;
}

label {
  font-size: 1rem;
}

/* .flex-item-1 {
  order: 1;
}

.flex-item-2 {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  flex: 1;
  order: -1;
} */
.calc-invalid-input-field {
  box-shadow: inset 0 0 4px 2px red;
}
</style>
