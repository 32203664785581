import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import BioCalculators from "@/views/BioCalculators.vue";
// import OlabSubscription from "@/views/OlabSubscription.vue";
// import SubscriptionForm from "@/views/SubscriptionForm.vue";
import Molarity from "@/views/calc/Molarity.vue";
import Dilution from "@/views/calc/Dilution.vue";
import MediumBuffer from "@/views/calc/MediumBuffer.vue";
import Quick from "@/views/calc/Quick.vue";
import MicrobialGrowth from "@/views/calc/MicrobialGrowth.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/home/:sectionName",
    name: "OlabMission",
    component: Home
  },
  {
    path: "/home/:sectionName",
    name: "WhatWeDo",
    component: Home,
    props: true
  },
  {
    path: "/home/:sectionName",
    name: "Contactus",
    component: Home,
    props: true
  },
  // {
  //   path: "/subscription",
  //   name: "OlabSubscription",
  //   component: OlabSubscription
  // },
  // {
  //   path: "/home/subscription/:type",
  //   name: "SubscriptionForm",
  //   component: SubscriptionForm,
  //   props: true
  // },
  {
    path: "/biocalculators",
    name: "biocalculators",
    component: BioCalculators
  },
  // {
  //   path: "/about",
  //   name: "OlabAbout",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/OlabAbout.vue")
  // },
  {
    path: "/molarity",
    name: "molarity",
    component: Molarity
  },
  {
    path: "/dilution",
    name: "dilution",
    component: Dilution
  },
  {
    path: "/mediumbuffer",
    name: "mediumbuffer",
    component: MediumBuffer
  },
  {
    path: "/quick",
    name: "quick",
    component: Quick
  },
  {
    path: "/microbialgrowth",
    name: "microbialgrowth",
    component: MicrobialGrowth
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/"
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
