<template>
  <div class="home">
    <!-- <img alt="OLAB Bioscience logo" src="../assets/OLAB.svg" />
    <div class="h5 my-2">
      <span class="text-info">Synthetic Biology</span>
      <span class="text-success"> made easy</span>
    </div>
    <div class="my-3">
      <h1 class="text-warning">****************</h1>
    </div> -->
    <!-- <p>Tag is {{ $route.params.tag }}</p>
    <p>prop: Tag is {{ tag }}</p> -->
    <OlabMission id="mission"></OlabMission>
    <WhatWeDo id="whatwedo"></WhatWeDo>
    <Contactus id="contactus"> </Contactus>
  </div>
</template>

<script>
// @ is an alias to /src
import OlabMission from "@/components/OlabMission.vue";
import WhatWeDo from "@/components/WhatWeDo.vue";
import Contactus from "@/components/Contactus.vue";

export default {
  name: "OlabHome",
  props: ["sectionName"],
  components: {
    OlabMission,
    WhatWeDo,
    Contactus
  },
  mounted() {
    console.log("mounted: sectionName = " + this.sectionName);
    this.scrollSection(this.sectionName);
  },
  methods: {
    scrollSection(id) {
      if (id === "mission" || id === "whatwedo" || id === "contactus") {
        // console.log("XXXXX id =", id, ", this =", this);
        const top = document.getElementById(id).offsetTop; //Getting Y of target element
        // console.log("XXXXX id =", id, ", top =", top);
        // Offset for fixed-top (70px)
        window.scrollTo(0, top - 70);
      }
    }
  },
  watch: {
    sectionName() {
      // console.log("watch: sectionName = " + this.sectionName);
      this.scrollSection(this.sectionName);
    }
  }
};
</script>
