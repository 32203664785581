<template>
  <section class="contactus" id="contactus">
    <form class="form" v-on:submit.prevent="handleSubmit">
      <div class="container">
        <div class="col-lg-10 col-12 mx-auto">
          <div class="contactusbox">
            <h2 class="mb-3 text-brand-color">Contact Us</h2>
            <div
              v-if="addResult === 'succeeded'"
              class="col-12 alert alert-info fade show px-3"
            >
              <span>{{ addMessage }}</span>
              <div class="text-center mt-2">
                <button
                  type="button"
                  class="btn btn-primary"
                  v-on:click="handleOK()"
                >
                  OK
                </button>
              </div>
            </div>
            <div
              v-if="addResult === 'failed'"
              class="col-12 alert alert-danger text-center px-3"
            >
              {{ addMessage }}
              <div class="text-center mt-2">
                <button
                  type="button"
                  class="btn btn-danger"
                  v-on:click="handleOK()"
                >
                  OK
                </button>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12 col-md-6 mb-3">
                <label for="full-name">Full name</label>
                <input
                  type="text"
                  required
                  class="form-control"
                  placeholder="Full name"
                  v-model="name"
                />
              </div>
              <div class="col-12 col-md-6 mb-3">
                <label for="inputEmail1">Email address</label>
                <input
                  type="email"
                  required
                  class="form-control"
                  placeholder="Email"
                  id="inputEmail1"
                  aria-describedby="emailHelp"
                  v-model="email"
                />
                <small id="emailHelp" class="form-text text-muted"
                  >We'll never share your email with anyone else.</small
                >
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12 col-md-6 mb-3">
                <label for="formControlSelect"
                  >How did you hear about us?</label
                >
                <select
                  class="form-control"
                  id="formControlSelect"
                  v-model="hearAboutUs"
                >
                  <option disabled value="null">Please select one</option>
                  <option value="Friends">Friends</option>
                  <option value="Peer Referral">Peer Referral</option>
                  <option value="Third Party Review">Third Party Review</option>
                  <option value="Search Engine">Search Engine</option>
                  <option value="Social Media">Social Media</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div class="col-12 col-md-6 mb-3">
                <label for="questionInquiryTextArea">Question/Inquiry</label>
                <textarea
                  required
                  class="form-control"
                  id="questionInquiryTextArea"
                  rows="5"
                  v-model="question"
                ></textarea>
              </div>
            </div>
            <br />
            <button class="btn btn-brand" type="submit">SUBMIT</button>
          </div>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import { ref, watch } from "vue";
import { dbFS, timestampFS } from "../firebase/config";

export default {
  name: "ContactUs",
  setup() {
    const name = ref("");
    const email = ref("");
    const hearAboutUs = ref(null);
    const question = ref("");
    const addResult = ref("inprogress");
    const addMessage = ref("");

    const handleSubmit = async () => {
      const contact = {
        full_name: name.value,
        email: email.value,
        hear_about_us: hearAboutUs.value,
        question: question.value,
        createdAt: timestampFS()
      };

      const res = await dbFS.collection("contacts").add(contact);
      // console.log(res);
      if (res && res.id) {
        addResult.value = "succeeded";
        addMessage.value = "Thank you! We will contact you shortly!";
      } else {
        addResult.value = "failed";
        addMessage.value =
          "We're sorry we encountered something technical difficulty at the moment. Please try again later";
      }
      name.value = "";
      email.value = "";
      hearAboutUs.value = null;
      question.value = "";
    };

    const handleOK = async () => {
      // console.log("Close Status Alert ...");
      addResult.value = "";
      addMessage.value = "";
    };

    watch(addResult, () => {
      if (addResult.value) {
        setTimeout(() => {
          addResult.value = "";
          addMessage.value = "";
        }, 10000);
      }
    });

    return {
      name,
      email,
      hearAboutUs,
      question,
      addResult,
      addMessage,
      handleSubmit,
      handleOK
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only --->
<style scoped lang="scss">
.contactus {
  padding: 50px 0;
  // background-color: #fde2d9;
  background-image: linear-gradient(45deg, #f5a183, #ffc75f, #fde2d9);
  background-size: 500%;
  animation: bg-animation 30s infinite alternate;
}

@keyframes bg-animation {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}
</style>
