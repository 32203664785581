class OlabCalcMicrobial {
  constructor() {
    this._initMicrobial();
  }
  _initMicrobial() {
    this.roundPrecisionDigit = 4;
    this.outputField = "";
    this.calculateField = "";
    this.stockOD = "";
    this.cultureOD = "";
    this.generationTime = "";
    this.cultureTime = "";
    this.cultureVolume = "";
    this.stockVolume = "";
    this.cultureVolumeUnit = {
      mL: {
        unitName: "mL",
        factor: 1e-3
      },
      L: {
        unitName: "L",
        factor: 1
      }
    };
  }
  reset() {
    this.calculateField = "";
    this.stockOD = "";
    this.cultureOD = "";
    this.generationTime = "";
    this.cultureTime = "";
    this.cultureVolume = "";
    this.stockVolume = "";
    // console.log("reset button");
  }
  getTempTMV() {
    return this.tempTestMemberValue;
  }
  setOutputField() {
    // logic in class is fine, calculator obj should determine which field is empty and then set as the outputfield

    // Previous logic accounting for case where input field is 0 (vs "")
    // Should be fine for 0 to block, better to explicitly have "" mark the calculateField
    if (this.calc.stockOD == "") {
      this.outputField = "calculateStockOD";
    } else if (this.calc.cultureOD == "") {
      this.outputField = "calculateCultureOD";
    } else if (this.calc.generationTime == "") {
      this.outputField = "calculateGenerationTime";
    } else if (this.calc.cultureTime == "") {
      this.outputField = "calculateCultureTime";
    } else if (this.calc.cultureVolume == "") {
      this.outputField = "calculateCultureVolume";
    } else if (this.calc.stockVolume == "") {
      this.outputField = "calculateStockVolume";
    }
  }
  // TODO: think about best way to handle setting which input field displays the output result
  recalculateField(outputField) {
    switch (outputField) {
      case "calculateStockOD":
        this.calculateStockOD();
        break;
      case "calculateCultureOD":
        this.calculateCultureOD();
        break;
      case "calculateGenerationTime":
        this.calculateGenerationTime();
        break;
      case "calculateCultureTime":
        this.calculateCultureTime();
        break;
      case "calculateCultureVolume":
        this.calculateCultureVolume();
        break;
      case "calculateStockVolume":
        this.calculateStockVolume();
        break;
      default:
        break;
    }
    return {};
  }
  // TODO error  and exceptions handling

  _generations() {
    return this.cultureTime / this.generationTime;
  }
  _computedStockOD() {
    return this.cultureOD / 2 ** this._generations();
  }
  _invCompStockOD() {
    return (this.stockOD * this.stockVolume) / this.cultureVolume;
  }
  _invGenerations() {
    return Math.log2(this.cultureOD / this._invCompStockOD());
  }
  calculateStockOD() {
    const scaledSOD =
      (this._computedStockOD() * this.cultureVolume) / this.stockVolume;
    this.stockOD =
      parseFloat(scaledSOD).toPrecision(this.roundPrecisionDigit) / 1;
  }
  calculateCultureOD() {
    const scaledCOD = 2 ** this._generations() * this._invCompStockOD();
    this.cultureOD =
      parseFloat(scaledCOD).toPrecision(this.roundPrecisionDigit) / 1;
  }
  calculateGenerationTime() {
    const scaledGT = this.cultureTime / this._invGenerations();
    this.generationTime =
      parseFloat(scaledGT).toPrecision(this.roundPrecisionDigit) / 1;
  }
  calculateCultureTime() {
    const scaledCT = this._invGenerations() * this.generationTime;
    this.cultureTime =
      parseFloat(scaledCT).toPrecision(this.roundPrecisionDigit) / 1;
  }
  calculateCultureVolume() {
    const scaledCV =
      (this.stockVolume * this.stockOD) / this._computedStockOD();
    this.cultureVolume =
      parseFloat(scaledCV).toPrecision(this.roundPrecisionDigit) / 1;
  }
  calculateStockVolume() {
    const scaledSV =
      (this._computedStockOD() * this.cultureVolume) / this.stockOD;
    this.stockVolume =
      parseFloat(scaledSV).toPrecision(this.roundPrecisionDigit) / 1;
  }
}

export { OlabCalcMicrobial };
