<template>
  <OlabNavigation />
  <router-view :errorObj="errorObj" @setErrorMessage="setErrorMessage" />
  <OlabFooter></OlabFooter>
</template>

<script>
import OlabNavigation from "@/components/OlabNavigation.vue";
import OlabFooter from "@/components/OlabFooter.vue";

export default {
  name: "App",
  data() {
    return {
      errorObj: {
        message: null
      }
    };
  },
  components: {
    OlabNavigation,
    OlabFooter
  },
  methods: {
    setErrorMessage(errMsg) {
      this.errorObj.message = errMsg;
    }
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");
@import "node_modules/bootstrap/scss/bootstrap";

:root {
  --gray-gradient: linear-gradient(
    45deg,
    #1d2481,
    #306d66,
    #7d7d54,
    #816657,
    #725072,
    #c9c8c1
  );
  --color-gradient: linear-gradient(
    45deg,
    #845ec2,
    #d65db1,
    #ff6f91,
    #ff9671,
    #ffc75f,
    #f9f871
  );

  --clr-pink-neon: hsl(317 100% 54%);
  --clr-green-neon: hsl(123, 59%, 52%);
  --clr-bg: hsl(323 21% 16%);
}

// Offset for fixed-top
body {
  padding-top: 70px;
}

.brand-color {
  background-color: #e70;
}

.text-brand-color {
  color: #e70;
}

.btn-brand {
  color: white;
  background: #e70;
}

#app {
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #555;
}
</style>
