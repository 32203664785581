<template>
  <div class="fixed-top">
    <header>
      <nav class="navbar navbar-expand-md navbar-light bg-light">
        <div class="container-fluid">
          <router-link class="navbar-brand" to="/home">
            <img
              alt="OLAB Bioscience logo"
              src="../assets/OLAB.svg"
              height="30"
            />
          </router-link>
          <button
            type="button"
            class="navbar-toggler"
            data-toggle="collapse"
            data-target="#togglerNav"
            aria-controls="#togglerNav"
            aria-label="Toggle Navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="togglerNav">
            <div class="navbar-nav ml-auto">
              <!-- <router-link class="nav-item nav-link" to="/">Home</router-link> -->
              <router-link
                class="nav-item nav-link"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                :to="{ name: 'WhatWeDo', params: { sectionName: 'mission' } }"
                >Mission</router-link
              >
              <router-link
                class="nav-item nav-link"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                :to="{ name: 'WhatWeDo', params: { sectionName: 'whatwedo' } }"
                >What We Do</router-link
              >
              <router-link
                class="nav-item nav-link"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                :to="{
                  name: 'Contactus',
                  params: { sectionName: 'contactus' }
                }"
                >Contact Us</router-link
              >
              <!-- <router-link
                class="nav-item nav-link"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                to="/subscription"
                >Subscription</router-link
              > -->
              <router-link
                class="nav-item nav-link"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                to="/biocalculators"
                >Bio-Calculators</router-link
              >
              <!-- <router-link
                class="nav-item nav-link"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                to="/about"
                >About Us</router-link
              > -->
            </div>
          </div>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
export default {
  name: "OlabNavigation"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
a {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
  padding: 10px;
  border-radius: 4px;

  &.router-link-exact-active {
    background: #e70;
  }
}
</style>
